<template>
  <div>
    <Head/>
    <section>
      <div class="left">
        <div class="leftTitle">
          <div>RAKUMART-找货助手</div>
          <div>历史版本</div>
        </div>
        <div class="versionsListContainer">
          <div v-for="(item,index) in versionsList" :key="index" class="versionsItemContainer cursorPointer"
               @click="activeIndex=index;">
            <div :style="activeIndex==index?'font-weight: 600;color: #B4272B;':''">{{ item.version }}</div>
            <div>更新时间：{{ item.created_at }}</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="rightTitle">RAKUMART-找货助手 {{ versionsList[activeIndex].version }}</div>
        <div class="rightNewTitle">更新内容</div>
        <div v-html="versionsList[activeIndex].description"></div>
      </div>
    </section>
  </div>
</template>

<script>
import Head from '@/components/head/pluginHomePageTop.vue'

export default {
  components: {Head},
  data() {
    return {
      versionsList: [],
      activeIndex: 0
    }
  },
  created() {
    this.$api.pluginVersionList().then((res) => {
      this.versionsList = res.data;
    });
  }
}
</script>

<style lang="scss" scoped>
section {
  width: 1400px;
  margin: 20px auto 0;
  display: flex;
  justify-content: space-between;

  .left {
    width: 260px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 0 0 30px;
    max-height: 458px;

    .leftTitle {
      margin-bottom: 35px;

      div:first-child {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 8px;
      }

      div:last-child {
        font-size: 14px;
        color: #666666;
      }
    }

    .versionsListContainer {
      overflow-y: scroll;
      height: 340px;

      .versionsItemContainer {
        margin-bottom: 30px;

        div:first-child {
          font-size: 16px;
          color: #000000;
          margin-bottom: 7px;
        }

        div:last-child {
          font-size: 12px;
          color: #999999;
        }
      }
    }

    .versionsListContainer::-webkit-scrollbar {
      width: 0;
    }
  }

  .right {
    width: 1114px;
    height: 800px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 32px 34px 0;
    box-sizing: border-box;

    .rightTitle {
      padding-bottom: 26px;
      font-size: 24px;
      font-weight: bold;
      color: #010101;
      border-bottom: 1px solid #DFDFDF;
      margin-bottom: 30px;
    }

    .rightNewTitle {
      font-size: 18px;
      font-weight: bold;
      color: #010101;
      margin-bottom: 40px;
    }
  }
}
</style>