<template>
  <div class="page">
    <div>
      <img alt="" class="cursorPointer" src="../../assets/1688/commoditySearchPageLogo.png"/>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.page {
  height: 56px;
  width: 100%;
  background: #B4272B;

  div {
    width: 1400px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    height: 56px;

    img {
      width: 180px;
    }
  }
}
</style>